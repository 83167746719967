/* import __COLOCATED_TEMPLATE__ from './share-button.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ReportingCustomReportShare from 'embercom/components/reporting/custom/report/share';
import type ReportAccessService from 'embercom/services/report-access-service';
import type Report from 'embercom/models/reporting/custom/report';
import { trackedFunction } from 'ember-resources/util/function';
import { isPresent } from '@ember/utils';

interface Args {
  disabled: boolean;
  report: Report;
}

export default class ReportingCustomReportShareButton extends Component<Args> {
  @service declare modalService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;

  reportAccessLoader = trackedFunction(this, async () => {
    try {
      return await this.reportAccessService.fetchReportAccess.perform(this.args.report.id);
    } catch (e) {
      if (e.name === 'TaskCancelation') {
        return [];
      } else {
        throw e;
      }
    }
  });

  get hasWorkspaceAccess(): boolean {
    // we use lastSuccessful here instead of reportAccessLoader.value, in case fetchReportAccess is performed
    // elsewhere and there is fresher data
    let accessControlList = this.reportAccessService.fetchReportAccess.lastSuccessful?.value || [];
    return isPresent(accessControlList.find((control) => control.isWorkspaceAccessControl));
  }

  get shareIcon() {
    return this.hasWorkspaceAccess ? 'multiple-people' : 'locked';
  }

  @action
  handleModalOpen() {
    this.modalService.openModal(ReportingCustomReportShare, { report: this.args.report });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::ShareButton': typeof ReportingCustomReportShareButton;
    'reporting/custom/report/share-button': typeof ReportingCustomReportShareButton;
  }
}
