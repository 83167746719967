/* import __COLOCATED_TEMPLATE__ from './banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import templateOnlyComponent from '@ember/component/template-only';
import type Chart from 'embercom/models/reporting/custom/chart';

interface Signature {
  Args: {
    chart: Chart;
    deleteChart: () => void;
    editMode: boolean;
  };
}

const BannerComponent = templateOnlyComponent<Signature>();
export default BannerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Bespoke::BannerComponent': typeof BannerComponent;
    'reporting/bespoke/banner-component': typeof BannerComponent;
  }
}
