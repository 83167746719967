/* import __COLOCATED_TEMPLATE__ from './share.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type NativeArray from '@ember/array/-private/native-array';
import { A } from '@ember/array';
import { first, isEmpty, last } from 'underscore';
import type Admin from 'embercom/models/admin';
import { action } from '@ember/object';
import type Report from 'embercom/models/reporting/custom/report';
import type ReportAccessService from 'embercom/services/report-access-service';
import { AccessType } from 'embercom/services/report-access-service';
import { dropTask, task } from 'ember-concurrency-decorators';
import type ReportAccessControlList from 'embercom/models/reporting/report-access-control-list';
import { taskFor } from 'ember-concurrency-ts';
import { cached } from 'tracked-toolbox';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { isPresent } from '@ember/utils';

interface Args {
  model: { report: Report };
}

interface Signature {
  Args: Args;
}

const SEARCH_LIMIT = 5;

export interface AccessOption {
  text: string;
  description: string;
  icon: InterfaceIconName;
  accessType: AccessType | null;
}

interface SelectedValue {
  text: string;
  description: string;
  icon: InterfaceIconName;
  accessType: AccessType | null;
}
export default class ReportingCustomReportShare extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;
  @service declare reportAccessService: ReportAccessService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare modalService: $TSFixMe;
  @service declare intercomEventService: any;
  @service declare notificationsService: $TSFixMe;

  @tracked selectedTeammates: NativeArray<Admin> = A([]);
  @tracked userInput = '';
  @tracked accessControlList: ReportAccessControlList[] = [];
  @tracked controlsLoaded = 0;

  @tracked declare selectedValue: string;
  @tracked focusActive = false;
  @tracked selectedAccessType: AccessType = AccessType.RESTRICTED_VIEW;

  sharedAnalyticsData = {
    object: 'report_access',
    section: 'reports',
  };

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadAccessControlTask.perform();
  }

  get report() {
    return this.args.model.report;
  }

  @action
  permissionHasConflict(admin: Admin, accessType: AccessType | null) {
    if (admin.isNotHuman) {
      return false;
    }
    let adminPermission = admin.currentAppPermissions;

    if (
      accessType &&
      [AccessType.VIEW, AccessType.RESTRICTED_VIEW].includes(accessType) &&
      !adminPermission.can_access_reporting
    ) {
      return true;
    } else if (accessType === AccessType.EDIT && !adminPermission.can_reporting__reports__update) {
      return true;
    }
    return false;
  }

  @action
  isCreator(admin: Admin) {
    return admin.id === this.report.createdById && admin.isHuman;
  }

  get tableData(): ReportAccessControlList[] {
    return this.accessControlList
      .filter((control) => !control.isWorkspaceAccessControl && isPresent(control.admin))
      .sort((a, b) => {
        // sort the owner to be first in the list
        if (a.adminId === this.reportOwner?.id) {
          return -1;
        } else if (b.adminId === this.reportOwner?.id) {
          return 1;
        } else {
          return a.admin!.name.localeCompare(b.admin!.name);
        }
      });
  }

  get selectedTeammatesId() {
    return new Set(this.selectedTeammates.map((teammate) => teammate.id));
  }

  get shareGroupList() {
    return [
      {
        items: [
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.full-access'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.full-access-description',
            ),
            icon: 'edit',
            value: AccessType.EDIT,
          },
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.explore-only'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.explore-only-description',
            ),
            icon: 'search',
            value: AccessType.VIEW,
          },
          {
            text: this.intl.t('reporting.custom-reports.report.share-modal.view-only'),
            description: this.intl.t(
              'reporting.custom-reports.report.share-modal.view-only-description',
            ),
            icon: 'eye',
            value: AccessType.RESTRICTED_VIEW,
          },
        ],
      },
    ];
  }

  get accessTypeTranslation() {
    let translations = {
      [AccessType.EDIT]: 'reporting.custom-reports.report.share-modal.full-access',
      [AccessType.VIEW]: 'reporting.custom-reports.report.share-modal.explore-only',
      [AccessType.RESTRICTED_VIEW]: 'reporting.custom-reports.report.share-modal.view-only',
    };

    return this.intl.t(translations[this.selectedAccessType]);
  }

  @cached
  get currentAdmin() {
    return this.appService.app.currentAdmin;
  }

  get availableTeammates() {
    let selectedTeammatesId = this.selectedTeammatesId;
    let invitedAdminIds = new Set(this.accessControlList.map(({ adminId }) => adminId));
    return this.store
      .peekAll('admin')
      .filter((admin) => admin.isHuman)
      .reject(
        (admin) =>
          selectedTeammatesId.has(admin.id) || admin.is_me || invitedAdminIds.has(admin.id),
      ); // we don't want to share with ourselve, admin selected for sharing or someone we already shared with :-)
  }

  get workspaceOptions() {
    return [
      {
        text: this.intl.t('reporting.custom-reports.report.share-modal.restricted'),
        onSelect: () => {
          this.unsetWorkspaceAccess();
        },
        icon: 'locked' as InterfaceIconName,
        isSelected: !isPresent(this.workspaceAccessControl),
        description: this.intl.t(
          'reporting.custom-reports.report.share-modal.restricted-description',
        ),
      },
      {
        text: this.appService.app.name,
        onSelect: () => {
          this.setWorkspaceAccess();
        },
        icon: 'company' as InterfaceIconName,
        isSelected: isPresent(this.workspaceAccessControl),
        description: this.intl.t('reporting.custom-reports.report.share-modal.shared-description'),
      },
    ];
  }

  @action
  unsetWorkspaceAccess() {
    if (this.workspaceAccessControl) {
      this.accessControlList.removeObject(this.workspaceAccessControl);
    }
  }

  @action
  setWorkspaceAccess() {
    if (!this.workspaceAccessControl) {
      let accessControl = this.store.createRecord('reporting/report-access-control-list', {
        reportId: this.report.id,
        accessType: AccessType.RESTRICTED_VIEW,
        adminId: null,
      });
      this.accessControlList.addObject(accessControl);
    } else {
      this.workspaceAccessControl.accessType = AccessType.RESTRICTED_VIEW;
    }
  }

  get suggestedTeammates() {
    if (isEmpty(this.userInput)) {
      return this.availableTeammates.slice(0, SEARCH_LIMIT);
    }
    return this.availableTeammates
      .filter((admin: Admin) => this.compareWithoutAccents(admin.name, this.userInput))
      .slice(0, SEARCH_LIMIT);
  }

  private removeAccents(name: string) {
    // normalize("NFD"): This breaks the accented characters into their base characters and the diacritical marks.
    // .replace(/[\u0300-\u036f]/g, ""): This removes the diacritical marks by targeting the Unicode range for diacritics.
    return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  private compareWithoutAccents(value: string, target: string) {
    let cleanedValue = value.toLowerCase();
    let cleanedTarget = target.toLowerCase();
    return this.removeAccents(cleanedValue).includes(this.removeAccents(cleanedTarget));
  }

  get firstSuggestTeammate() {
    return this.selectedTeammates[0];
  }

  @action
  selectTeammate(teammate?: Admin) {
    // avoid adding empty teammates
    if (teammate) {
      this.selectedTeammates.addObject(teammate);
    }
  }

  get hasSuggestedTeammates() {
    return this.suggestedTeammates.length > 0;
  }

  @action
  clearUserInput() {
    this.userInput = '';
  }

  @action
  runSearch(e: InputEvent & { target: HTMLInputElement }) {
    this.userInput = e.target.value;
  }

  get columns() {
    return [
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.who-has-access'),
        valuePath: 'admin.name',
        type: 'avatar-with-text',
        isSortable: false,
      },
      {
        label: this.intl.t('reporting.custom-reports.report.share-modal.access'),
        valuePath: 'accessType',
        isSortable: false,
      },
    ];
  }

  get sharingHasChanged() {
    return (
      this.controlsLoaded !== this.accessControlList.length ||
      this.accessControlList.some((control) => control.get('hasDirtyAttributes'))
    );
  }

  @dropTask
  *confirmChangesAndClose() {
    if (this.sharingHasChanged) {
      let confirmed: boolean = yield this.intercomConfirmService.confirm({
        title: this.intl.t('reporting.custom-reports.report.share-modal.confirmation-modal.title'),
        body: this.intl.t('reporting.custom-reports.report.share-modal.confirmation-modal.body'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'reporting.custom-reports.report.share-modal.confirmation-modal.confirm-btn-text',
        ),
        distinguishCancelFromClose: false,
      });
      if (confirmed) {
        this.closeModalAndReset();
      }
    } else {
      this.closeModalAndReset();
    }
  }

  closeModalAndReset() {
    this.clearUserInput();
    this.selectedTeammates.clear();
    this.selectedAccessType = AccessType.RESTRICTED_VIEW;
    this.focusActive = false;
    this.modalService.closeModal();
  }

  get removingOwnEditAccess() {
    let currentAdminAccess = this.accessControlList.find(
      (control) => control.adminId === this.currentAdmin.id,
    );
    return (
      currentAdminAccess?.accessType !== AccessType.EDIT &&
      this.workspaceAccessControl?.accessType !== AccessType.EDIT
    );
  }

  @dropTask
  *saveChanges() {
    if (this.removingOwnEditAccess) {
      let confirmed: boolean = yield this.intercomConfirmService.confirm({
        title: this.intl.t(
          'reporting.custom-reports.report.share-modal.removal-warning-modal.title',
        ),
        body: this.intl.t('reporting.custom-reports.report.share-modal.removal-warning-modal.body'),
        primaryButtonType: 'primary-destructive',
        confirmButtonText: this.intl.t(
          'reporting.custom-reports.report.share-modal.removal-warning-modal.confirm-btn-text',
        ),
      });
      if (!confirmed) {
        return;
      }
    }
    yield taskFor(this.reportAccessService.updateReportAccess).perform(
      this.report,
      this.accessControlList,
    );

    this.notificationsService.notifyConfirmation(
      this.intl.t('reporting.custom-reports.report.share-modal.save-success'),
    );

    // Reload so records are no longer considered dirty
    yield this.loadAccessControlTask.perform();

    if (this.removingOwnEditAccess) {
      this.closeModalAndReset();
      // This is necessary so the share button is disabled correctly,
      // ideally this wouldn't be necessary as the disabled state should be derived from
      // this report's access control list
      this.reportAccessService.loadAdminReportAccess();
    }
  }

  get placeHolder() {
    return isEmpty(this.selectedTeammates)
      ? this.intl.t('reporting.custom-reports.report.share-modal.invite-teammates')
      : undefined;
  }

  @action
  handleBackspace() {
    if (isEmpty(this.userInput)) {
      this.selectedTeammates = this.selectedTeammates.slice(0, this.selectedTeammates.length - 1);
    }
  }

  @action
  handleInput(
    popover: { hide: () => void; show: () => void },
    e: InputEvent & { target: HTMLInputElement },
  ) {
    this.userInput = e.target.value;
    if (isEmpty(this.userInput)) {
      popover.hide();
    } else {
      popover.show();
    }
  }

  @action
  handleEnter(popover: { hide: () => void; show: () => void }) {
    let selectedTeamate = first(this.suggestedTeammates);
    if (selectedTeamate && !isEmpty(this.userInput)) {
      this.selectTeammate(selectedTeamate);
      this.clearUserInput();
      popover.hide();
    }
  }

  @action
  handleClickSelection(teammate: Admin, popover: { hide: () => void; show: () => void }) {
    this.selectTeammate(teammate);
    this.clearUserInput();
    this.focusOnInput();
    popover.hide();
  }
  @action
  handleDelete(selectedTeammate: Admin) {
    this.selectedTeammates = this.selectedTeammates.reject(
      (teammate) => teammate.id === selectedTeammate.id,
    );
  }

  @action
  changeAccess(reportAccessControlList: ReportAccessControlList, selectedValue: SelectedValue) {
    reportAccessControlList.accessType = selectedValue.accessType!;
  }

  @action
  changeWorkspaceAccess(selectedValue: SelectedValue) {
    // TODO can this just be replaced by changeAccess above? When would accessType be null?
    if (selectedValue.accessType && this.workspaceAccessControl) {
      this.workspaceAccessControl.accessType = selectedValue.accessType;
    }
  }

  @action
  addTeammates() {
    let teammatesToAdd = this.selectedTeammates.map((teammate) =>
      this.store.createRecord('reporting/report-access-control-list', {
        reportId: this.report.id,
        accessType: this.selectedAccessType,
        adminId: teammate.id,
      }),
    );
    this.accessControlList.addObjects(teammatesToAdd);
    this.selectedTeammates.clear();
  }

  @action
  setShareAccessType(value: string) {
    this.selectedAccessType = value as AccessType;
  }

  private get reportOwner(): Admin | null {
    return this.report.isIntercomOwnedReport
      ? null
      : this.store.peekRecord('admin', this.report.createdById);
  }

  @task({ drop: true })
  *loadAccessControlList() {
    // Clean up any previously loaded records
    this.accessControlList.forEach((record) => record.unloadRecord());

    let result: ReportAccessControlList[] =
      yield this.reportAccessService.fetchReportAccess.perform(this.report.id);
    // Track the number loaded to detect changes
    this.controlsLoaded = result.length;
    // Clone the array to make it mutable
    this.accessControlList = result.toArray();
  }

  loadAccessControlTask = taskFor(this.loadAccessControlList);

  get disableSuggestion() {
    // we don't want to suggested teammates when we are loading invited teammates or when app has no teammates
    return !this.hasSuggestedTeammates || this.loadAccessControlTask.isRunning;
  }

  get workspaceAccessControl(): ReportAccessControlList | undefined {
    return this.accessControlList.find((control) => control.isWorkspaceAccessControl);
  }

  @action
  removeAccess(reportAccessControlList: ReportAccessControlList) {
    this.accessControlList.removeObject(reportAccessControlList);
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'delete',
      report_id: this.report.id,
      is_workspace: reportAccessControlList.isWorkspaceAccessControl,
      access_type: reportAccessControlList.accessType,
    });
  }

  @action
  getAdminName(selectedAdmin: Admin) {
    if (this.isCreator(selectedAdmin) && this.currentAdmin.id === selectedAdmin.id) {
      // display "YOU" only when logged-in user is creator
      return this.intl.t('reporting.custom-reports.report.share-modal.you');
    } else {
      return selectedAdmin.name;
    }
  }

  get reportUrl() {
    return `${window.location.origin}/a/apps/${this.appService.app.id}/reports/custom-reports/report/${this.report.id}`;
  }

  @action
  revokeAccessForReport() {
    let adminIdsToKeep = new Set([this.currentAdmin.id, this.reportOwner?.id].compact());
    this.accessControlList = this.accessControlList.filter((control) =>
      adminIdsToKeep.has(control.adminId),
    );
    this.intercomEventService.trackAnalyticsEvent({
      ...this.sharedAnalyticsData,
      action: 'revoked',
      report_id: this.report.id,
    });
  }

  @action
  focusOnInput() {
    this.focusActive = true;
  }

  get mostRecentlyAddedTeammate() {
    return last(this.selectedTeammates);
  }

  get otherWorkspaceAdmins() {
    return this.tableData.reject((control) => this.isCreator(control.admin!));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reporting::Custom::Report::Share': typeof ReportingCustomReportShare;
    'reporting/custom/report/share': typeof ReportingCustomReportShare;
  }
}
