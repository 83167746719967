/* import __COLOCATED_TEMPLATE__ from './classify-conversation-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';

interface Signature {
  Element: HTMLElement;
  Args: {
    action: any;
    onChange?: () => void;
    isDisabled?: boolean;
    onDelete?: () => void;
  };
  Blocks: {
    default: [args: { opener: unknown; label: string; tooltip: string }];
  };
}

interface ClassifiableAttributeItem {
  value: string;
  text?: string;
  isDisabled: boolean;
  tooltipText?: string;
  componentShouldReplaceItem?: boolean;
  component?: string;
}

export default class ClassifyConversationAttribute extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare attributeService: any;
  @service declare intl: any;

  get attributes() {
    return this.attributeService.conversationAttributeDescriptors.toArray() as Array<{
      identifier: string;
      name: string;
      dataType: string;
      isClassifiable: boolean;
      archived: boolean;
      systemDefined: boolean;
    }>;
  }

  get classifiableAttributeItems(): ClassifiableAttributeItem[] {
    let classifiableAttributeItems = this.attributes
      .filter((attribute) => {
        return (
          attribute.identifier === this.attributeIdentifier ||
          (attribute.dataType === 'list' && !attribute.archived && !attribute.systemDefined)
        );
      })
      .map((attribute) => ({
        value: attribute.identifier,
        text: attribute.name,
        isDisabled: !attribute.isClassifiable,
        tooltipText:
          !attribute.isClassifiable &&
          this.intl.t(
            'settings.inbox-rules.index.actions.classify-conversation-attribute.disabled-tooltip',
          ),
      })) as ClassifiableAttributeItem[];

    if (isEmpty(classifiableAttributeItems)) {
      classifiableAttributeItems = [
        {
          value: '',
          isDisabled: true,
          componentShouldReplaceItem: true,
          component: 'rules/actions/classify-conversation-attribute/empty-item',
        },
      ] as ClassifiableAttributeItem[];
    }

    return classifiableAttributeItems;
  }

  get conversationDataAttribute() {
    return this.attributes.find(
      (attribute: any) => attribute.identifier === this.attributeIdentifier,
    );
  }

  get attributeIdentifier() {
    return this.args.action.get('actionData.attribute_identifier');
  }

  get label() {
    return this.intl.t('settings.inbox-rules.index.actions.classify-conversation-attribute.name', {
      name: this.conversationDataAttribute?.name,
      htmlSafe: true,
    });
  }

  get tooltip() {
    return this.intl.t(
      'settings.inbox-rules.index.actions.classify-conversation-attribute.tooltip',
      {
        name: this.conversationDataAttribute?.name,
      },
    );
  }

  @action
  updateAttribute(identifier: string) {
    this.args.action.actionData = { attribute_identifier: identifier };

    let onChange = this.args.onChange;
    if (onChange) {
      onChange();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Rules::Actions::ClassifyConversationAttribute': typeof ClassifyConversationAttribute;
  }
}
